import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  FAQ,
  STOCK,
  PERENCANAAN_SHARIA,
  SALDO,
  PERENCANAAN,
  GADAI,
  EMASPLUS, 
  DASHBOARD,
  ORDERS,
  SETTINGS,
  PENARIKAN,
  CUSTOMERS,
  COUPONS,
  LIST_ORDER,
  LIST_ORDER_GSI,
  LIST_ALAMAT,
  CATEGORY_NASABAH,
  LIST_ORDER_WITHDRAW,
  RETAIL_PRODUCT,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  REKAP,
  REKAP_EMAS_KELUAR,
  REKAP_USER,
  REKAP_USER_TRANSACTION,
  REKAP_PAJAK,
  REKAP_UANG_KELUAR,
  REKAP_GADAI,
  REKAP_GADAI_NONGOLD,
  REKAP_GADAI_DETAIL,
  REKAP_UANG_MASUK,
  GADAI_DASHBOARD,
  REKAP_BANK,
  REKAP_BANK_NASABAH,
  LOG_MUTASI,
  RANGKING_DISTRI,
  VERIFIKASI,
  VERIFIKASI_BSI,
  DETAIL_RANKING,
  RANKING_TARIK_FISIK,
  LIST_TYPE,
  HARGA_DIGITAL,
  GADAIS,
  PERINGKAT,
  RANKING_DIGITAL,
  HISTORY_EMAS,
  HISTORY_PERAK,
  CASHBACK_MASTER,
  PRICE_LIST,
  UPDATE_EMAS,
  UPDATE_PERAK,
  NASABAH,
  RANKING_GADAI,
  PENARIKAN_EMAS,
  AGENT,
  NEWS,
  PENARIKAN_WADIAH,
  DISTRIBUTOR,
  BANNER_PROMOTION,
  REKAP_VOUCHER,
  REKAP_VOUCHER_DETAIL,
  REKAP_SALDO,
  REKAP_SALDO_DETAIL_PERENCANAAN,
  REKAP_SALDO_DETAIL_PLUS,
  REKAP_SALDO_DETAIL_EMASDORMANT,
  REKAP_SALDO_DETAIL_PERENCANAANDORMANT,
  REKAP_SALDO_DETAIL_TARIKEMAS,
  REKAP_PAJAK_DETAIL,
  TERMS_CONDITION,
  REKAP_EMAS_KELUAR_DETAIL,
  HELP_CENTER,
  NASABAH_BIRTHDAY,
  NASABAH_BIRTHDAY_DISTRIBUTOR,
  ADMIN,
  SETTING,
  TRANSAKSI_GADAI,
  INVOICE_GADAI,
  JATUH_TEMPO,
  DETAIL_REKAP_BANK_NASABAH,
  REKAP_GADA_LUNAS,
  ADD_MUTATION,
  PEMBAYARAN_CICILAN,
  LOGS_USER,
  TOP_UP,
  PRICE_HOME,
  NASABAH_VERIFIED,
  REKAP_REVENUE,
  REKAP_POSISI_GADAI,
  NOTIF,NOTIFMAIL,
  PRIVATESEND,
  PUBLICSEND, AGEN_GADAI, CICIL_KENDARAAN, KPR, ORDER_KENDARAAN, REKAP_NOTIF, REKAP_DIGIPROD
} from 'settings/constants';
import AuthProvider, { AuthContext } from 'context/auth';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
const Notif = lazy(() => import('containers/Notif/Notif'));
const PublicSend = lazy(() => import('./containers/PublicSend/PublicSend'));
const PrivateSend = lazy(() => import('./containers/PrivateSend/PrivateSend'));
const PrivateSendMail = lazy(() => import('./containers/PrivateSend/PrivateSendMail'));
const Agent = lazy(() => import('./containers/Agent/Agent'));
const InvoiceGadai = lazy(() => import('./containers/Gadais/InvoiceGadai'));
const GadaiDashboard = lazy(() => import('./containers/Gadai/GadaiDashboard'));
const AddMutation = lazy(() => import('./containers/Gadai/AddMutationForm'));
const PembayaranCicilan = lazy(() => import('./containers/Gadai/PembayaranCicilanForm'));
const JatuhTempo = lazy(() => import('./containers/Gadai/GadaiDashboardTempo'));
const Topup = lazy(() => import('./containers/Topup/Topup'));
const PriceHome = lazy(() => import('./containers/PriceHome/PriceHome'));
const TransaksiGadai = lazy(() => import('./containers/Gadais/TransaksiGadai'));
const CashbackMaster = lazy(() => import('./containers/CashbackMaster/CashbackMaster'));
const Distributor = lazy(() => import('./containers/Distributor/Distributor'));
const BannerPromotion = lazy(() => import('./containers/BannerPromotion/Banner'));
const Products = lazy(() => import('containers/Products/Products'));
const News = lazy(() => import('containers/News/News'));
const Peringkat = lazy(() => import('containers/Peringkat/Peringkat'));
const CategoryNasabah = lazy(() => import('containers/CategoryNasabah/CategoryNasabah'));
const HistoryEmas = lazy(() => import('containers/History/Emas'));
const UpdateEmas = lazy(() => import('containers/HargaDigital/Emas'));
const UpdatePerak = lazy(() => import('containers/HargaDigital/Emas'));
const HistoryPerak = lazy(() => import('containers/History/Perak'));
const PerencanaanSharia = lazy(() => import('containers/PerencanaanSharia/PerencanaanSharia'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Category/Category'));
const Alamat = lazy(() => import('containers/ListAlamat/ListAlamat'));
const ListType = lazy(() => import('containers/HargaTarikFisik/ListType'));
const PriceList = lazy(() => import('containers/HargaTarikFisik/PriceList'));
const RangkingTarikFisik = lazy(() => import('containers/RangkingDistributor/RangkingTarikFisik'));
const RangkingDigital = lazy(() => import('containers/RangkingDistributor/RangkingDigital'));
const RangkingDistri = lazy(() => import('containers/RangkingDistributor/RangkingDistributor'));
const RankingGadai = lazy(() => import('containers/RangkingDistributor/RangkingGadai'));
const DetailRanking = lazy(() => import('containers/RangkingDistributor/DetailRanking'));
const Gadai = lazy(() => import('containers/Gadai/Gadai'));
const Kpr = lazy(() => import('containers/Gadai/Kpr'));
const CicilKendaraan = lazy(() => import('containers/Gadai/CicilKendaraan'));
const Gadais = lazy(() => import('containers/Gadais/Gadais'));
const Nasabah = lazy(() => import('containers/Nasabah/Nasabah'));
const NasabahVerified = lazy(() => import('containers/Nasabah/NasabahVerified'));
const Emasplus = lazy(() => import('containers/PerencanaanSharia/EmasPlus'));
const AgenGadai = lazy(() => import('containers/Gadai/AgenGadai'));
const Faq = lazy(() => import('containers/Faq/Faq'));
const LogMutasi = lazy(() => import('containers/LogMutasi/LogMutasi'));
const Stock = lazy(() => import('containers/Stock/Stock'));
const RetailProduct = lazy(() => import('containers/RetailProduct/RetailProduct'));
const Rekap = lazy(() => import('containers/Rekap/Rekap'));
const RekapNotif = lazy(() => import('containers/Rekap/RekapNotif'));
const RekapDigiprod = lazy(() => import('containers/Rekap/RekapDigiprod'));
const Setting = lazy(() => import('containers/Setting/Setting'));
const RekapVoucher = lazy(() => import('containers/Rekap/RekapVoucher'));
const ListOrder = lazy(() => import('containers/ListOrder/ListOrder'));
const ListOrderKendaraan = lazy(() => import('containers/ListOrder/ListOrderKendaraan'));
const ListOrderGSI = lazy(() => import('containers/ListOrder/ListOrderGSI'));
const ListOrderWithdraw = lazy(() => import('containers/ListOrder/ListOrderWithdraw'));
const RekapBank = lazy(() => import('containers/Rekap/RekapBank'));
const RekapBankNasabah = lazy(() => import('containers/Rekap/RekapBankNasabah'));
const RekapSaldoDetailPerencanaan = lazy(() => import('containers/Rekap/RekapSaldoDetailPerencanaan'));
const RekapSaldoDetailDormantEmas = lazy(() => import('containers/Rekap/RekapSaldoDetailDormantEmas'));
const RekapSaldoDetailDormantPerencanaan = lazy(() => import('containers/Rekap/RekapSaldoDetailDormantPerencanaan'));
const RekapSaldoDetailTarikEmas = lazy(() => import('containers/Rekap/RekapSaldoDetailTarikEmas'));
const RekapEmasKeluarDetail = lazy(() => import('containers/Rekap/RekapEmasKeluarDetail'));
const RekapRevenue = lazy(() => import('containers/Rekap/RekapGadaiRevenue'));
const RekapPosisiGadai = lazy(() => import('containers/Rekap/RekapPosisiGadai'));
const RekapSaldoDetailPlus = lazy(() => import('containers/Rekap/RekapSaldoDetailPlus'));
const RekapSaldo   = lazy(() => import('containers/Rekap/RekapSaldo'));
const RekapPajakDetail   = lazy(() => import('containers/Rekap/RekapPajakDetail'));
const Saldo = lazy(() => import('containers/Saldo/Saldo'));
const Perencanaan = lazy(() => import('containers/PerencanaanSharia/Perencanaan'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const OrderKendaraan = lazy(() => import('containers/Orders/OrderKendaraan'));
const Term = lazy(() => import('containers/TermCondition/TermCondition'));
const Verifikasi = lazy(() => import('containers/VerifikasiTransaksi/Verifikasi'));
const VerifikasiBSI = lazy(() => import('containers/VerifikasiTransaksi/VerifikasiBSI'));
const Settings = lazy(() => import('containers/Settings/Settings'));
const HargaDigital = lazy(() => import('containers/HargaDigital/HargaDigital'));
const Penarikan = lazy(() => import('containers/Penarikan/Penarikan'));
const PenarikanEmas = lazy(() => import('containers/Penarikan/Emas'));
const PenarikanWadiah = lazy(() => import('containers/Penarikan/Wadiah'));
const RekapEmasKeluar = lazy(() => import('containers/Rekap/RekapEmasKeluar'));
const RekapUser = lazy(() => import('containers/Rekap/RekapUser'));
const RekapUserTransaction = lazy(() => import('containers/Rekap/RekapUserTransaction'));
const RekapPajak = lazy(() => import('containers/Rekap/RekapPajak'));
const RekapUangKeluar = lazy(() => import('containers/Rekap/RekapUangKeluar'));
const RekapUangMasuk = lazy(() => import('containers/Rekap/RekapUangMasuk'));
const RekapGadai = lazy(() => import('containers/Rekap/RekapGadai'));
const RekapGadaiNongold = lazy(() => import('containers/Rekap/RekapGadaiNongold'));
const RekapGadaiLunas = lazy(() => import('containers/Rekap/RekapGadaiLunas'));
const RekapGadaiDetail = lazy(() => import('containers/Rekap/RekapGadaiDetail'));
const DetailRekapBankNasabah = lazy(() => import('containers/Rekap/RekapBankNasabahDetail'));

const RekapVoucherDetail = lazy(() => import('containers/Rekap/RekapVoucherDetail'));
const SiteSettingForm = lazy(() =>
  import('containers/SiteSettingForm/SiteSettingForm')
);
const StaffMembers = lazy(() => import('containers/StaffMembers/StaffMembers'));
const Customers = lazy(() => import('containers/Customers/Customers'));
const Coupons = lazy(() => import('containers/Coupons/Coupons'));
const Login = lazy(() => import('containers/Login/Login'));
const Logs = lazy(() => import('containers/Logs/Log'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));
const HelpCenter = lazy(() => import('containers/HelpCenter/HelpCenter'));
const Admin = lazy(() => import('containers/Admin/Admin'));
const NasabahBirthday = lazy(() => import('containers/NasabahBirthday/NasabahBirthday'));
const NasabahBirthdayDistributor = lazy(() => import('containers/NasabahBirthdayDistributor/NasabahBirthdayDistributor'));
/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>          
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={GADAI_DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <GadaiDashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={ADD_MUTATION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AddMutation />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PEMBAYARAN_CICILAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PembayaranCicilan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={JATUH_TEMPO}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <JatuhTempo />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={TRANSAKSI_GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TransaksiGadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={NOTIF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Notif />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={NOTIFMAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PrivateSendMail />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PRIVATESEND}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PrivateSend />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PUBLICSEND}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PublicSend />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={INVOICE_GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InvoiceGadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={ADMIN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Admin />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={LOGS_USER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Logs />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={NEWS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <News />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={SETTING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Setting />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={NASABAH_BIRTHDAY_DISTRIBUTOR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <NasabahBirthdayDistributor />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PERINGKAT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Peringkat />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_REVENUE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapRevenue />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_NOTIF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapNotif />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_DIGIPROD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapDigiprod />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_POSISI_GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapPosisiGadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_SALDO_DETAIL_PERENCANAAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapSaldoDetailPerencanaan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_SALDO_DETAIL_EMASDORMANT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapSaldoDetailDormantEmas />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_SALDO_DETAIL_PERENCANAANDORMANT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapSaldoDetailDormantPerencanaan/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_SALDO_DETAIL_PLUS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapSaldoDetailPlus/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_SALDO_DETAIL_TARIKEMAS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapSaldoDetailTarikEmas/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_SALDO}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapSaldo />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={CASHBACK_MASTER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CashbackMaster />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_VOUCHER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapVoucher />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_VOUCHER_DETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapVoucherDetail />
                </Suspense>
                </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={NASABAH_BIRTHDAY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <NasabahBirthday />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={NASABAH_VERIFIED}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <NasabahVerified />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={GADAIS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Gadais />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PENARIKAN_EMAS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PenarikanEmas />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={HELP_CENTER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <HelpCenter />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PENARIKAN_WADIAH}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PenarikanWadiah/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_PAJAK_DETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapPajakDetail/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={REKAP_EMAS_KELUAR_DETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapEmasKeluarDetail/>
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={RANKING_DIGITAL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RangkingDigital />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={RANKING_GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RankingGadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={HISTORY_EMAS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <HistoryEmas />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={UPDATE_EMAS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <UpdateEmas />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={AGENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Agent />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={TOP_UP}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Topup />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PRICE_HOME}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PriceHome />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={TERMS_CONDITION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Term />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={DISTRIBUTOR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Distributor />
                </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={RANKING_TARIK_FISIK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RangkingTarikFisik />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={UPDATE_PERAK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <UpdatePerak />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={DETAIL_REKAP_BANK_NASABAH}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DetailRekapBankNasabah />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={LIST_TYPE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ListType />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={PRICE_LIST}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PriceList />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={DETAIL_RANKING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DetailRanking />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={HISTORY_PERAK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <HistoryPerak />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={LOG_MUTASI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <LogMutasi />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={HARGA_DIGITAL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <HargaDigital />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={CATEGORY_NASABAH}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CategoryNasabah />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={RANGKING_DISTRI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RangkingDistri />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={VERIFIKASI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Verifikasi />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={VERIFIKASI_BSI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <VerifikasiBSI />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={LIST_ORDER_WITHDRAW}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ListOrderWithdraw />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={LIST_ORDER_GSI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ListOrderGSI />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={ORDER_KENDARAAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ListOrderKendaraan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PERENCANAAN_SHARIA}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PerencanaanSharia />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={NASABAH}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Nasabah />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_EMAS_KELUAR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapEmasKeluar />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_UANG_MASUK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapUangMasuk />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapGadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_GADAI_NONGOLD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapGadaiNongold />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_GADA_LUNAS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapGadaiLunas />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_GADAI_DETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapGadaiDetail />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_UANG_KELUAR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapUangKeluar />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_PAJAK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapPajak />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_USER_TRANSACTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapUserTransaction />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_USER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapUser />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={AGEN_GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AgenGadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> 

          <PrivateRoute path={LIST_ORDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ListOrder />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LIST_ALAMAT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Alamat />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_BANK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapBank />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP_BANK_NASABAH}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RekapBankNasabah />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BANNER_PROMOTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BannerPromotion />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PENARIKAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Penarikan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={REKAP}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Rekap />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={RETAIL_PRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RetailProduct />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={FAQ}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Faq />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STOCK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Stock />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SALDO}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Saldo />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PERENCANAAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Perencanaan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={GADAI}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Gadai />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CICIL_KENDARAAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CicilKendaraan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={KPR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Kpr />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EMASPLUS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Emasplus />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
         
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPONS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupons />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
