import React from 'react';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  BASE_URL:Function;
  formatRupiah:Function;
  convertToGram:Function;
  conv:Function;
  enumConverter:Function;
  dateNow:Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem('pickbazar_token');
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};
const BASE_URL = () => {
 
return 'https://ibank.shariacoin.co.id:5902/api';
// return 'http://localhost:8000/api';

};
function dateNow() {
  var todayDate = new Date().toISOString().slice(0, 10);  

  return  todayDate;
}

export const enumConverter = (val) => {
  switch (val) {
    case 'SHRC_WITHDRAW':
      return 'Penjualan Instant SHRC'
    case 'VA_MANDIRI':
      return 'VA MANDIRI' 
    case 'VA_BNI':
      return 'VA BNI'
    case 'VA_PERMATA':
      return 'VA PERMATA'
     

    case 'VA_BRI':
      return 'VA BRI'
    case 'ETH_WITHDRAW':
      return 'Penjualan Instant ETH'
    case 'SREC_WITHDRAW':
      return 'Penjualan Instant ETH'
    case 'SREC_TOPUP':
      return 'Pembelian Instant SREC'
    case 'SHRC_TOPUP':
      return 'Pembelian Instant SHRC'
    case 'ETH_TOPUP':
      return 'Pembelian Instant ETH'
    case 'PAWN_GOLD_EXTENSION':
      return 'Perpanjang Gadai'
    case 'PAWN_INSTALLMENT':
      return 'Pembiayaan Non Gold'
    case 'GOLD_TOPUP':
      return 'Pembelian Emas'
    case 'SILVER_TOPUP':
      return 'Pembelian Perak'
    case 'WADIAH_TOPUP':
      return 'Top Up Wadiah'
    case 'ASSET_WITHDRAW':
      return 'Penarikan Fisik'
    case 'PAWN_GOLD_SETTLEMENT':
      return 'Pelunasan Gadai'
    case 'PLAN_PROGRESS':
      return 'Progres'
    case 'INSTALLMENT_DOWN_PAYMENT':
      return 'Uang Muka Cicilan'
    case 'INVESTMENT_WADIAH':
      return 'Wadiah Plus'
    case 'INVESTMENT_GOLD':
      return 'Emas Plus'
    case 'DISTRIBUTOR_PURCHASE':
      return 'Pembelian Retail'
    case 'TOPUP_ADMIN':
      return 'Top Up Admin'
    case 'TAX_CHARGE':
      return 'Pajak'
    case 'DEBIT':
      return 'D'
    case 'CREDIT':
      return 'C' 
    default:
      return val
  }
}
const formatRupiah=(bilangans) =>{
  var bilangan = bilangans?bilangans:0
  var	number_string = bilangan.toString(),
	sisa 	= number_string.length % 3,
	rupiah 	= number_string.substr(0, sisa),
	ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
		
  if (ribuan) {
    var separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
    return 'Rp '+ rupiah;
  }
  return '0'
 }

 const formatNoRupiah=(bilangans) =>{
  var bilangan = bilangans?bilangans:0
  var	number_string = bilangan.toString(),
	sisa 	= number_string.length % 3,
	rupiah 	= number_string.substr(0, sisa),
	ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
		
  if (ribuan) {
    var separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
    return rupiah;
  }
  return '0'
 }


 const conv=(bilangans) =>{
  var bilangan = bilangans?bilangans:0
  var	number_string = bilangan.toString(),
	sisa 	= number_string.length % 3,
	rupiah 	= number_string.substr(0, sisa),
	ribuan 	= number_string.substr(sisa).match(/\d{3}/g);
		
if (ribuan) {
	var separator = sisa ? '.' : '';
	rupiah += separator + ribuan.join('.');
  return  rupiah;
}
return '0'
  
 }
 const convertToGram = (val) => {
  if (val) {
      return parseFloat(val).toString().substring(0,7).replace("." , ",");
  }
  return '0'
}
const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  function authenticate({ email, password }, cb) {
    makeAuthenticated(true);
    localStorage.setItem('pickbazar_token', `${email}.${password}`);
    setTimeout(cb, 100); // fake async
  }
  function signout(cb) {    
    fetch(BASE_URL()+'/logout', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }
    })  
  .then(response => response.json())
   .then(() => {
    makeAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.clear()
    setTimeout(cb, 100);
   })
    
   makeAuthenticated(false);
   localStorage.removeItem('token');
   setTimeout(cb, 100);
   
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        BASE_URL,
        dateNow,
        formatRupiah,
        enumConverter,
        convertToGram,
        conv
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
