// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const FAQ = '/faq';
export const STOCK = '/stock';
export const SALDO = '/saldo';
export const PERENCANAAN = '/perencanaan';
export const GADAI = '/gadai';
export const KPR = '/kpr';
export const CICIL_KENDARAAN = '/cicil-kendaraan';
export const ORDER_KENDARAAN = '/order-kendaraan';
export const EMASPLUS = '/emasPlus';
export const WADIAHPLUS = '/wadiahPlus';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const SETTING = '/setting';
export const PENARIKAN = '/penarikan';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const RETAIL_PRODUCT = '/retail-product';
export const VERIFIKASI = '/verifikasi';
export const VERIFIKASI_BSI = '/verifikasi-bsi';
export const STOCK_LIST = '/stock-list';
export const REKAP = '/rekap';
export const REKAP_BANK = '/rekap-bank';
export const REKAP_NOTIF = '/rekap-notif';
export const REKAP_DIGIPROD = '/rekap-digiprod';
export const LOG_MUTASI = '/log-mutasi';
export const LIST_ORDER = '/list-order';
export const LIST_ORDER_GSI = '/list-order-gsi';
export const LIST_ORDER_WITHDRAW = '/list-order-withdraw';
export const PERENCANAAN_SHARIA = '/list-perencanaan';
export const REKAP_BANK_NASABAH = '/rekap-bank-nasabah';

export const LIST_ALAMAT = '/list-alamat';
export const NASABAH = '/nasabah';
export const RANGKING_DISTRI = '/rangking-distri';
export const CATEGORY_NASABAH = '/category-nasabah'; 
export const HARGA_DIGITAL = '/harga-digital'; 
export const HISTORY_EMAS = '/history-emas'; 
export const HISTORY_PERAK = '/history-perak'; 
export const UPDATE_EMAS = '/update-emas'; 
export const UPDATE_PERAK = '/update-perak'; 
 
export const AGENT= '/agent';
export const DISTRIBUTOR = '/distributor';
export const BANNER_PROMOTION = '/banner-promotion'

export const LIST_TYPE = '/list-type'; 
export const PRICE_LIST = '/price-list'; 
export const DETAIL_RANKING = '/detail-ranking';
export const RANKING_TARIK_FISIK = '/ranking-tarik-fisik'; 
export const PERINGKAT = '/peringkat'; 
export const RANKING_GADAI = '/ranking-gadai'; 
export const RANKING_DIGITAL = '/ranking-digital';
export const PENARIKAN_EMAS = '/penarikan-emas'; 
export const PENARIKAN_WADIAH = '/penarikan-wadiah'; 
export const GADAIS = '/gadais';
export const CASHBACK_MASTER = '/cashback-master'; 
export const REKAP_REVENUE = '/rekap-revenue';
export const REKAP_VOUCHER = '/rekap-voucher'; 
export const REKAP_VOUCHER_DETAIL = '/rekap-voucher-detail'; 
export const REKAP_SALDO = '/rekap-saldo'; 
export const REKAP_SALDO_DETAIL_PERENCANAAN = '/rekap-saldo-detail-perencanaan'; 
export const REKAP_SALDO_DETAIL_PLUS = '/rekap-saldo-detail-plus'; 
export const REKAP_SALDO_DETAIL_EMASDORMANT = '/rekap-saldo-detail-emasdomant'; 
export const REKAP_SALDO_DETAIL_PERENCANAANDORMANT = '/rekap-saldo-detail-perencanaandomant'; 
export const REKAP_SALDO_DETAIL_TARIKEMAS = '/rekap-saldo-detail-tarikemas'; 
export const REKAP_EMAS_KELUAR = '/rekap-emas-keluar';
export const REKAP_USER = '/rekap-user';
export const REKAP_USER_TRANSACTION = '/rekap-user-transaction';
export const REKAP_PAJAK = '/rekap-pajak';
export const REKAP_UANG_KELUAR = '/rekap-uang-keluar';
export const REKAP_GADAI = '/rekap-gadai';
export const REKAP_GADAI_NONGOLD = '/rekap-gadai-nongold';
export const REKAP_GADA_LUNAS = '/rekap-gadai-lunas';
export const REKAP_GADAI_DETAIL = '/rekap-gadai-detail';
export const REKAP_UANG_MASUK = '/rekap-uang-masuk';
export const REKAP_PAJAK_DETAIL = '/rekap-pajak-detail';
export const REKAP_EMAS_KELUAR_DETAIL = '/rekap-emas-keluar-detail';
export const TERMS_CONDITION = '/term-condition';
export const HELP_CENTER = '/help-center';
export const NASABAH_BIRTHDAY = '/nasabah-birthday';
export const NASABAH_BIRTHDAY_DISTRIBUTOR = '/nasabah-birthday-distributor';
export const ADMIN = '/admin';
export const GADAI_DASHBOARD = '/gadai-dashboard';
export const JATUH_TEMPO= '/jatuh-tempo';
export const NEWS = '/news';
export const AGEN_GADAI = '/agen-gadai';
export const DETAIL_REKAP_BANK_NASABAH = '/detail-rekap-bank-nasabah';
export const TRANSAKSI_GADAI= '/transaksi-gadai';
export const INVOICE_GADAI= '/invoice-gadai';
export const LOGS_USER = '/logs'
export const ADD_MUTATION= '/add-mutation';
export const ADD_PLAFOND= '/add-plafond';
export const PEMBAYARAN_CICILAN= '/pembayaran-cicilan';
export const NASABAH_VERIFIED = '/nasabah-verified';
export const TOP_UP = '/topup';
export const PRICE_HOME = '/price-home';
export const REKAP_POSISI_GADAI = '/rekap-posisi-gadai';
export const NOTIF = '/notif'
export const PRIVATESEND = '/private-send';
export const NOTIFMAIL = '/private-sendmail';
export const PUBLICSEND = '/public-send';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
